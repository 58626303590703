<template>
  <div>
      <!-- <div class="mb-4">
        <h5 class="text-center">
          Rasio Pengguna Hak Suara
        </h5>
        <b-progress class="mt-3" :max="100" height="2rem">
          <b-progress-bar :value="20">
            <span>20%</span>
          </b-progress-bar>
        </b-progress>
      </div> -->
      <!-- <b-row v-if="this.$store.getters['account/accountData'].role !== 'caleg'" class="mb-4">
        <b-col cols="12" md="2">
          <v-select placeholder="Pilih Regional" v-model="model.parliamentRegion" :disabled="list.parliamentRegions.length < 1" :reduce="option => option.value" :options="list.parliamentRegions" :clearable="false"></v-select>
        </b-col>
        <b-col cols="12" md="3" v-if="model.parliamentRegion && model.parliamentRegion === list.parliamentRegions[2].value">
          <v-select placeholder="Pilih Kab" v-model="model.city" :disabled="list.city.length < 1" :reduce="option => option.value" :options="list.city" :clearable="false"></v-select>
        </b-col>
        <b-col cols="12" md="3">
          <v-select placeholder="Pilih Dapil" v-model="model.dapil" :disabled="list.dapil.length < 1" :reduce="option => option.value" :options="list.dapil" :clearable="false"></v-select>
        </b-col>
      </b-row> -->
    <template v-if="this.$store.getters['account/accountData'].role === 'staff-calon'">
      <b-row v-if="parentCalon" style="justify-content: center;">
        <b-col cols="4" md="3" class="mb-3">
          <b-card class="party-dashboard-list h-100 d-flex align-items-center justify-content-center" body-class="d-flex align-items-center justify-content-center flex-wrap">
            <div>
              <img :src="parentCalon.user_detail.avatar ? parentCalon.user_detail.avatar : '/assets/images/calon.png'" :alt="parentCalon.name" class="img-fluid" >
            </div>
            <p class="pt-2 m-0 w-100 text-center">{{ parentCalon.name }}</p>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row v-if="this.$store.getters['account/accountData'].role !== 'caleg'" class="mb-0">
        <template v-if="gridFilter.province_id === null">
          <b-col cols="4" md="2" class="mb-3" v-for="(province, index) in grid.provinces" :key="index">
            <!-- <router-link class="card-d-link" :to="'parties/' + party.id + '/' + model.parliamentRegion + (model.dapil ? '/' + model.dapil : '')"> -->
              <b-card @click.prevent="setProvinceId(province.id)" :role="province.id ? 'button' : null" v-bind:class="{ 'opacity-50' : !province.id }" class="party-dashboard-list h-100 d-flex align-items-center justify-content-center" body-class="d-flex align-items-center justify-content-center flex-wrap">
                <div>
                  <img :src="`/assets/images/provinces/${province.logo}`" :alt="province.name" class="img-fluid" >
                </div>
                <p class="pt-2 m-0 w-100 text-center">{{ province.name }}</p>
              </b-card>
            <!-- </router-link> -->
          </b-col>
        </template>
        <template v-else-if="gridFilter.province_id !== null && gridFilter.city_id === null">
          <!-- <b-col cols="12" class="pb-3">
            <a href="#" @click.prevent="setProvinceId(null)"><font-awesome-icon icon="chevron-left"/> Kembali</a>
          </b-col> -->
          <b-col cols="4" md="2" class="mb-3" v-for="(city, index) in grid.cities" :key="index">
            <!-- <router-link class="card-d-link" :to="'parties/' + party.id + '/' + model.parliamentRegion + (model.dapil ? '/' + model.dapil : '')"> -->
              <b-card @click.prevent="setCityId(city.id)" :role="city.id ? 'button' : null" v-bind:class="{ 'opacity-50' : !city.id }" class="party-dashboard-list h-100 d-flex align-items-center justify-content-center" body-class="d-flex align-items-center justify-content-center flex-wrap">
                <div>
                  <img :src="`/assets/images/provinces/detail_${gridFilter.province_id}/${city.logo}`" :alt="city.name" class="img-fluid" >
                </div>
                <p class="pt-2 m-0 w-100 text-center">{{ city.name }}</p>
              </b-card>
            <!-- </router-link> -->
          </b-col>
        </template>
        <template v-else>
          <b-col cols="12" class="pb-3">
            <a href="#" @click.prevent="setCityId(null)"><font-awesome-icon icon="chevron-left"/> Kembali</a>
          </b-col>
          <b-col cols="12" class="pb-3">
            <b-row v-if="this.$store.getters['account/accountData'].role !== 'caleg' && gridFilter.city_id">
              <b-col cols="12" md="4" class="d-inline-flex align-items-center">
                <p class="m-0">Jumlah Pengguna Hak Suara: <strong>{{ $formatPrice(model.totalVoteUse) }}</strong></p>
              </b-col>
              <b-col cols="12" md="4" class="d-inline-flex align-items-center">
                <p class="m-0">Total DPT: <strong>{{ $formatPrice(model.totalDpt) }}</strong></p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4" md="3" class="mb-3" v-for="(calon, index) in grid.calon" :key="index">
              <b-card class="party-dashboard-list h-100 d-flex align-items-center justify-content-center" body-class="d-flex align-items-center justify-content-center flex-wrap">
                <div>
                  <img :src="calon.user_detail.avatar ? calon.user_detail.avatar : '/assets/images/calon.png'" :alt="calon.name" class="img-fluid" >
                </div>
                <p class="pt-2 m-0 w-100 text-center">{{ calon.name }}</p>
                <p class="pt-2 m-0 w-100 text-center">
                  <strong>Total Suara: {{ $formatPrice(calon.user_work_area.vote_count) }}</strong>
                </p>
              </b-card>
            <!-- </router-link> -->
          </b-col>
        </template>
      </b-row>

      <div v-if="this.$store.getters['account/accountData'].role === 'caleg'">
          <b-row class="filter-row mb-3" v-if="list.parliamentRegions.length > 1">
            <b-col cols="12" md="3">
              <v-select ref="filter-city_id" placeholder="Pilih Kota/Kabupaten" v-model="filter.city_id" :disabled="list.city.length < 1" :reduce="option => option.value" :options="list.city" :clearable="false"></v-select>
            </b-col>
          </b-row>
          <!-- <h2>Kecamatan</h2> -->
          <div>
            <b-row>
              <b-col cols="8">
                <h3>Pendukung <font-awesome-icon icon="spinner" spin v-if="loading.loadPendukung"></font-awesome-icon><b-btn v-else @click.prevent="getCalegPendukung(true)" type="button" size="sm" variant="link"><font-awesome-icon icon="recycle"/></b-btn></h3>
                <p>Total: {{ $formatPrice(counter.pendukung) }}</p>
                <a href="#" v-if="!loading.loadPendukung && filter.pendukung_region_type !== 'district'" @click.prevent="setBackPendukungRegion()"><font-awesome-icon icon="chevron-left"/> Kembali</a>
                <p class="mt-3" v-if="filter.pendukung_region_type !== 'district'">{{ filter.pendukung_region_parent_name }}</p>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-btn @click.prevent="doExportCalegPendukung">Export</b-btn>
              </b-col>
            </b-row>
            <div class="chart-container">
              <canvas id="chart-pendukung-content"/>
            </div>
            <small v-if="lastUpdated.pendukung" class="pb-3 d-block">Last Updated: {{ $moment(lastUpdated.pendukung).format('DD-MM-YYYY HH:mm') }}</small>
          </div>
          <div class="mt-3">
            <h3>Perolehan <font-awesome-icon icon="spinner" spin v-if="loading.loadVote"></font-awesome-icon><b-btn v-else @click.prevent="getCalegDistricts(true)" type="button" size="sm" variant="link"><font-awesome-icon icon="recycle"/></b-btn></h3>
            <p>Total: {{ $formatPrice(counter.suara) }}</p>
            <div class="chart-container">
              <canvas id="chart-suara-content"/>
            </div>
            <small v-if="lastUpdated.suara" class="pb-3 d-block">Last Updated: {{ $moment(lastUpdated.suara).format('DD-MM-YYYY HH:mm') }}</small>
          </div>
      </div>
    </template>
  </div>
</template>

<script>
import WebLocalStorage from '@/adapters/WebLocalStorage'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import formGroupInput from '@/components/FormGroupInput.vue'
import Dpt from '@/models/Dpt.js'
import City from '@/models/City.js'
import District from '@/models/District.js'
import Village from '@/models/Village.js'
import CalegCountSummary from '@/models/CalegCountSummary.js'
import Dapil from '@/models/Dapil.js'
import Pendukung from '@/models/Pendukung.js'
import ParliamentRegion from '@/models/ParliamentRegion.js'
import PendukungRegionStat from '@/models/PendukungRegionStat.js'

const pendukungModel = new Pendukung()
const dptModel = new Dpt()
const cityModel = new City()
const villageModel = new Village()
const districtModel = new District()
const calegCountSummaryModel = new CalegCountSummary()
const dapilModel = new Dapil()
const parliamentRegion = new ParliamentRegion()
const pendukungRegionStat = new PendukungRegionStat()
const webLocalStorageAdapter = new WebLocalStorage;

export default {
  components: {
    formGroupInput
  },
  data () {
    return {
      percentages: [],
      // initState: true,
      loading: {
        loadPendukung: false,
        loadVote: false,
      },
      model: {
        totalVoteUse: 0,
        totalDpt: 0,
        dapil: null,
        city: null,
        parliamentRegion: null
      },
      pendukung_region_pointers: {
        district: null,
        village: null,
      },
      filter: {
        city_id: null,
        pendukung_region_parent_name: null,
        pendukung_region_parent_id: null,
        pendukung_region_type: 'district',
        perolehan_region_type: 'district',
      },
      parentCalon: null,
      counter: {
        pendukung: 0,
        suara: 0,
      },
      chart: {
        pendukung: null,
        suara: null,
      },
      lastUpdated: {
        pendukung: false,
        suara: false,
      },
      grid: {
        provinces: [],
        cities: [],
        calon: [],
      },
      gridFilter: {
        province_id: null,
        city_id: null,
      },
      list: {
        parties: [],
        city: [],
        districts: [],
        villages: [],
        party_vote_counts: [],
        dapil: [],
        parliamentRegions: [],
        activeRegions: []
      },
      colorArrays: []
    }
  },
  beforeMount () {
    if (this.$store.getters['account/accountData'].role === 'caleg') {
      Chart.register(ChartDataLabels);
    } else {
      this.setProvinceId('950b9d3a-c996-4207-97e7-5a2858981979')
      // this.getGridProvinces()
    }
  },
  async mounted () {
    this.getCities()
    if (this.$store.getters['account/accountData'].role === 'caleg') {
      // this.$router.push('/perolehan-suara-calon')
      // cityModel.list({
      //   'name': 'labuhan',
      //   'with_pendukung_count': 1,
      //   'for_caleg': this.$store.getters['account/accountData'].id,
      //   'province_id': '950b9d3a-c996-4207-97e7-5a2858981979',
      //   'limit': 10000
      // }).then(resp => {
      //   const options = []
      //   resp.data.forEach(d => {
      //     options.push({
      //       value: d.id,
      //       label: d.name
      //     });
      //   });
      //   this.list.city = options
      // })
    } else if (this.$store.getters['account/accountData'].role === 'staff-calon') {
      this.getCalonDetail()
    } else {
      // await webLocalStorageAdapter.destroyCalegStorage()
      // this.getParties()
      // this.getParliamentRegions()
    }
  },
  watch: {
  //   // 'model.parliamentRegion': function (newValue) {
  //   //   this.model.dapil = null
  //   //   if (newValue) {
  //   //     if (this.model.parliamentRegion !== this.list.parliamentRegions[2].value) {
  //   //       this.model.city = null
  //   //     } else if (this.model.parliamentRegion === this.list.parliamentRegions[2].value) {
  //   //       this.model.city = this.list.city[0].value
  //   //     }

  //   //     this.getDapil()
  //   //     // this.getTotalDPt()
  //   //     // this.getTotalVote()

  //   //   }
  //   // },
  //   'model.dapil': function (newValue) {
  //     if (newValue) {
  //       this.getTotalDPt()
  //       this.getTotalVote()
  //     }
  //   },
    'model.city': function (newValue) {
      this.model.dapil = null
      if (newValue) {
        this.getDapil()
      }
    },
    'filter.city_id': async function (newValue) {
      this.resetPendukungRegion()
      if (newValue) {
        // console.log('initState', this.initState)
        // this.filter.pendukung_region_parent_id = newValue;
        await this.getCalegDistricts()
        await this.getCalegPendukung()
        // this.initState = false;
      }
    },
  },  
  methods: {
    async getCalonDetail () {
      const { data } = await this.$http.get(process.env.VUE_APP_API_URL + 'staff-calon/show-calon-detail')
      this.parentCalon = data
    },
    resetPendukungRegion () {
      this.filter.pendukung_region_type = 'district'
      this.filter.pendukung_region_parent_name = null
      this.filter.pendukung_region_parent_id = null
    },
    setBackPendukungRegion () {
      if (this.filter.pendukung_region_type === 'tps') {
        // this.pendukung_region_pointers.tps = {
        //   id: null,
        //   name: null,
        // }
        this.filter.pendukung_region_type = 'village'
        this.filter.pendukung_region_parent_name = this.pendukung_region_pointers.village.name
        this.filter.pendukung_region_parent_id = this.pendukung_region_pointers.village.id
      } else if (this.filter.pendukung_region_type === 'village') {
        this.pendukung_region_pointers.village = {
          id: null,
          name: null,
        }
        this.filter.pendukung_region_type = 'district'
        this.filter.pendukung_region_parent_name = this.pendukung_region_pointers.district.name
        this.filter.pendukung_region_parent_id = this.pendukung_region_pointers.district.id
      }
      this.getCalegPendukung()
    },
    setNextPendukungRegion (data) {
      if (this.filter.pendukung_region_type !== 'tps'){
        if (this.filter.pendukung_region_type === 'district') {
          this.pendukung_region_pointers.district = {
            id: this.filter.pendukung_region_parent_id,
            name: this.filter.pendukung_region_parent_name,
          };
          this.filter.pendukung_region_type = 'village'
        } else if (this.filter.pendukung_region_type === 'village') {
          this.pendukung_region_pointers.village = {
            id: this.filter.pendukung_region_parent_id,
            name: this.filter.pendukung_region_parent_name,
          };
          this.filter.pendukung_region_type = 'tps'
        }
        this.filter.pendukung_region_parent_name = data.name 
        this.filter.pendukung_region_parent_id = data.id 
        this.getCalegPendukung()
      }
    },
    doExportCalegPendukung () {
      if (this.loading.loadPendukung) {
        return false;
      }
      this.loading.loadPendukung = true;
      // const filterArray = [];
      // Object.keys(this.filter).forEach(key => {
      //   if (this.filter[key]) {
      //     if (key === 'city_id') {
      //       if (!this.hideCityOptions) {
      //         filterArray.push(key + "=" + this.filter[key]);
      //       }
      //     } else {
      //       filterArray.push(key + "=" + this.filter[key]);
      //     }
      //   }
      // });
      // filterArray.push('pdf=1');

      this.$http.get(pendukungModel.getEndpoint() + '/stats/pdf', 
        {
          params: this.filter,
          responseType: 'blob'
        }
      ).then((response) => {
        this.loading.loadPendukung = false;
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'data_total_pendukung.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        this.loading.loadPendukung = false;
        this.$store.dispatch('notification/error', error)
      })
    },
    getParties () {
      // partyModel.list({
      //   'limit': 10000
      // }).then(resp => {
      //   const partyList = []
      //   resp.data.forEach(d => {
      //     partyList.push({
      //       ... d,
      //       total_vote_count: 0
      //     })
      //   });
      //   this.list.parties = partyList
      // })
    },
    getGridProvinces () {
      this.grid.provinces = [];
      return fetch('/assets/json/provinces.json', {
        method: 'GET',
        headers: {
          // 'Authorization': 'Bearer ' + state.accessToken,
          'Content-Type': 'application/json'
        }
      }).then(response => response.json()).then(response => {
        this.grid.provinces = response
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    setProvinceId (id) {
      this.gridFilter.province_id = id;
      this.grid.cities = [];
      if (id) {
        return fetch('/assets/json/detail_' + id + '.json', {
          method: 'GET',
          headers: {
            // 'Authorization': 'Bearer ' + state.accessToken,
            'Content-Type': 'application/json'
          }
        }).then(response => response.json()).then(response => {
          this.grid.cities = response
        }).catch((error) => {
          return Promise.reject(error)
        })
      }
    },
    async setCityId (id) {
      this.gridFilter.city_id = id;
      if (id) {
        const { data } = await this.$http.get(process.env.VUE_APP_API_URL + 'users?limit=30&page=1&order=created_at&ascending=0&with_user_detail_only=1&with_user_work_area=1&roles=caleg&dapil_id=' + this.gridFilter.city_id);
        this.grid.calon = data.data;
        this.getTotalDPt()
        this.getTotalVote()
      }
    },
    getParliamentRegions () {
      parliamentRegion.list({
        'limit': 3
      }).then(resp => {
        const options = []
        resp.data.forEach(d => {
          options.push({
            value: d.id,
            label: d.name,
            detail: d
          });
        });
        this.list.parliamentRegions = options
        this.model.parliamentRegion = resp.data[0].id
        // this.getDapil()
      })
    },
    getDapil () {
      if (this.model.parliamentRegion === this.list.parliamentRegions[2].value && !this.model.city) {
        this.list.dapil = []
        return false;
      }
      dapilModel.list({
        'region_city_id': this.model.city ? this.model.city : '',
        'limit': 100,
        'parliament_region_id': this.model.parliamentRegion
      }).then(resp => {
        const options = []
        if (this.model.parliamentRegion !== this.list.parliamentRegions[0].value) {
          options.push({
            value: 'all',
            label: 'Semua'
          });
        }
        resp.data.forEach(d => {
          options.push({
            value: d.id,
            label: d.name
          });
        });
        this.list.dapil = options
        if (this.model.parliamentRegion !== this.list.parliamentRegions[0].value) {
          this.model.dapil = 'all'
        } else {
          this.model.dapil = resp.data[0].id
        }
      })
    },
    async getCities () {
      let cityResp = await webLocalStorageAdapter.dataCalegCities().get();
      if (!cityResp || !cityResp.total) {
        cityResp = await cityModel.list({
          'registered_only': 1,
          // 'name': 'labuhan', // nanti balikin
          'dapil_id': this.$store.getters['account/accountData'].role === 'caleg' ? this.$store.getters['account/accountData'].dapil.id : null,
          'province_id': this.$store.getters['account/accountData'].role === 'caleg'  ? null : '950b9d3a-c996-4207-97e7-5a2858981979',
          'limit': 10000
        })
        if (cityResp.data.length > 0) {
          await webLocalStorageAdapter.dataCalegCities().save(cityResp)
        }
      }

      const options = []
      cityResp.data.forEach(d => {
        options.push({
          value: d.id,
          label: d.name
        });
      });
      this.list.city = options
      if (this.$store.getters['account/accountData'].role === 'caleg') {
        this.filter.city_id = cityResp.data[0].id
        // this.filter.city_id = cityResp.data[2].id
      }
      /* cityModel.list({
        'registered_only': 1,
        // 'name': 'labuhan', // nanti balikin
        'dapil_id': this.$store.getters['account/accountData'].role === 'caleg' ? this.$store.getters['account/accountData'].dapil.id : null,
        'province_id': this.$store.getters['account/accountData'].role === 'caleg'  ? null : '950b9d3a-c996-4207-97e7-5a2858981979',
        'limit': 10000
      }).then(async resp => {
        if (resp.data.length > 0) {
          await this.$localDb.dataCalegCities().save(resp.data)
        }
        const options = []
        resp.data.forEach(d => {
          options.push({
            value: d.id,
            label: d.name
          });
        });
        this.list.city = options
        if (this.$store.getters['account/accountData'].role === 'caleg') {
          this.filter.city_id = resp.data[0].id
        }
      }) */
    },
    resetRandomAray() {
      this.colorArrays = []
    },
    generateRandomColor () {
      let color = Math.floor(Math.random()*16777215).toString(16);
      while (this.colorArrays.includes(color)) {
        color = Math.floor(Math.random()*16777215).toString(16);
      }
      this.colorArrays.push(color)
      // console.log(color)
      return '#' + color;
    },
    async getCalegDistricts (force = false) {
      this.loading.loadVote = true;
      let districtVoteCount = null;
      try {
        districtVoteCount = force ? false : await webLocalStorageAdapter.dataPerolehanCaleg(this.filter.city_id).get();
        if (!districtVoteCount || (districtVoteCount && !districtVoteCount.response.total)) {          
          districtVoteCount = await districtModel.list({
            'city_id': this.filter.city_id,
            'limit': 10000,
            'votes_count': 1,
            'for_caleg': this.$store.getters['account/accountData'].id,
          });
          // if (districtVoteCount.data.length > 0) {
            await webLocalStorageAdapter.dataPerolehanCaleg(this.filter.city_id).save({
              last_updated_at: new Date().toString(),
              response: districtVoteCount,
            })
            this.lastUpdated.suara = new Date().toString();
          // }
        } else {
          this.lastUpdated.suara = districtVoteCount.last_updated_at
          districtVoteCount = districtVoteCount.response
        }

        // this.list.districts = [];
        this.counter.suara = 0;
        const chartVoteCountLabels = [];
        const chartVoteCountDatas = [];
        const chartVoteCountBackgroundColors = [];
        const chartVoteCountBorderColors = [];
        this.resetRandomAray()
        districtVoteCount.data.forEach(district => {
          // this.list.districts.push(district);
          this.counter.suara += parseInt(district.vote_count);
          chartVoteCountLabels.push(district.name)
          chartVoteCountDatas.push(district.vote_count)
          const color = this.generateRandomColor()
          chartVoteCountBackgroundColors.push(color)
          chartVoteCountBorderColors.push(color)
        });
        this.initChartVoteCount ('Perolehan Per-Kecamatan', chartVoteCountLabels, chartVoteCountDatas, chartVoteCountBackgroundColors, chartVoteCountBorderColors);
        this.loading.loadVote = false;
        return Promise.resolve('success');
      } catch (e) {
        this.loading.loadVote = false;
        return Promise.reject(e);
      }
    },
    async getCalegPendukung (force = false) {
      let regionList = null
      if (this.filter.pendukung_region_type === 'tps') {
        regionList = await dptModel.maxTps({
          'village_id': this.filter.pendukung_region_parent_id,
        });
      } else if (this.filter.pendukung_region_type === 'village') {
        regionList = await villageModel.list({
          'district_id': this.filter.pendukung_region_parent_id,
          'limit': 10000,
          'for_caleg': this.$store.getters['account/accountData'].id,
        });
      } else {
        regionList = await webLocalStorageAdapter.dataPerolehanCaleg(this.filter.city_id).get();
        regionList = regionList.response
      }
      if (regionList && regionList.total) {
        // regionList = regionList
        this.list.activeRegions = regionList.data
        this.loading.loadPendukung = true;
        try {
          const regionIds = regionList.data.map(region => {
            return region.id
          })
          const storageNameSuffix = this.filter.pendukung_region_type + '_' + this.filter.city_id;
          let regionStat = false;
          let dptStat = []
          if (!force) {
            regionStat = await webLocalStorageAdapter.dataPendukungCaleg(storageNameSuffix).get();
          }
          // if (!regionStat || (regionStat && !regionStat.response.length)) {          
          if (!regionStat) {
            regionStat = await pendukungRegionStat.list({
              'group_type': this.filter.pendukung_region_type,
              'group_ids': regionIds,
              'user_id': this.$store.getters['account/accountData'].id,
              // 'limit': 100000
            });
            dptStat = await dptModel.stats({
              'group_type': this.filter.pendukung_region_type,
              'group_ids': regionIds,
              // 'user_id': this.$store.getters['account/accountData'].id,
              // 'limit': 100000
            });
            if (this.filter.pendukung_region_type === 'district' /* && regionStat.length > 0*/ ) {
              await webLocalStorageAdapter.dataPendukungCaleg(storageNameSuffix).save({
                last_updated_at: new Date().toString(),
                group_type: this.filter.pendukung_region_type,
                group_ids: regionIds,
                response: {
                  pendukungStats: regionStat,
                  dptStats: dptStat,
                },
              })
            }
            this.lastUpdated.pendukung = new Date().toString();
          } else {
            this.lastUpdated.pendukung = regionStat.last_updated_at
            dptStat = regionStat.response.dptStats
            regionStat = regionStat.response.pendukungStats
          }


          

          // this.list.districts = [];
          this.counter.pendukung = 0;
          const chartPendukungLabels = [];
          const chartPendukungDatas = [];
          const chartDptDatas = [];
          this.percentages = [];
          const chartPendukungBackgroundColors = [];
          const chartPendukungBorderColors = [];
          this.resetRandomAray()
          regionList.data.forEach(region => {
            chartPendukungLabels.push(region.name)

            const pendukungStatFind = regionStat.find(regionStatData => {
              return regionStatData.group_id === region.id
            })
            if (pendukungStatFind) {
              chartPendukungDatas.push(pendukungStatFind.total_pendukung)
              this.counter.pendukung += parseInt(pendukungStatFind.total_pendukung);
              
            } else {
              chartPendukungDatas.push(0)
            }

            const dptStatFind = dptStat.find(dptStatData => {
              return dptStatData.group_id === region.id
            })
            if (dptStatFind) {
              chartDptDatas.push(dptStatFind.total_dpt)
            } else {
              chartDptDatas.push(0)
            }

            if (pendukungStatFind && dptStatFind) {
              this.percentages.push((pendukungStatFind.total_pendukung / dptStatFind.total_dpt) * 100)
            } else {
              this.percentages.push(0);
            }

            // chartPendukungBackgroundColors.push('rgba(54, 162, 235)')
            // chartPendukungBorderColors.push('rgb(54, 162, 235)')
            const color = this.generateRandomColor()
            chartPendukungBackgroundColors.push(color)
            chartPendukungBorderColors.push(color)
          });

          this.initChartPendukung ('Pendukung', chartPendukungLabels, chartPendukungDatas, chartDptDatas, chartPendukungBackgroundColors, chartPendukungBorderColors);
          this.loading.loadPendukung = false;
          return Promise.resolve('success');
        } catch (e) {
          console.log(e)
          this.loading.loadPendukung = false;
          return Promise.reject(e);
        }
      }
    },
    initChartPendukung (chartLabel, chartPendukungLabels, chartPendukungDatas, chartDptDatas, chartPendukungBackgroundColors, chartPendukungBorderColors) {
      if (this.chart.pendukung) {
        this.chart.pendukung.destroy();
      }

      function hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
          return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }

      const dptColors = []
      chartPendukungBackgroundColors.forEach(color => {
        if (color) {
          const rgb = hexToRgb(color);
          if (!rgb) {
            dptColors.push(color)
          } else {
            dptColors.push(`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`);
          }
        }
      });

      this.chart.pendukung = new Chart(document.getElementById('chart-pendukung-content'), {
        type: 'bar',
        data: {
          labels: chartPendukungLabels,
          datasets: [{
            axis: 'y',
            label: chartLabel,
            data: chartPendukungDatas,
            fill: false,
            // barThickness: 50,
            backgroundColor: chartPendukungBackgroundColors,
            borderColor: chartPendukungBorderColors,
            borderWidth: 1
          }, {
            axis: 'y',
            label: 'DPT',
            data: chartDptDatas,
            fill: false,
            opacity: 0.6,
            // barThickness: 50,
            backgroundColor: dptColors,
            borderColor: dptColors,
            borderWidth: 1
          }]
        },
        options: {
          // responsive: true,
          maintainAspectRatio: window.innerWidth > 768,
          scales: {
            x: {
              grid: {
                display: false
              }
            },
            y: {
              grid: {
                display: true
              }
            }
          },
          indexAxis: 'y',
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              font: {
                size: 13,
                weight: 'bold'
              },
              color: '#fff',
              formatter: (value, context) => {
                if (context.dataset.label === 'Pendukung') {
                  const percentages = this.percentages[context.dataIndex];
                  return value && value > 0 ? `${this.$formatPrice(value)} ${percentages && percentages !== Infinity ? '(' + this.$formatPrice(percentages) + '%)' : ''}` : '';
                }
                return value && value > 0 ? this.$formatPrice(value) : '';
              }
            }
          },
          onClick: (event, elements, chart) => {
            if (elements[0] && !this.loading.loadPendukung) {       
              const i = elements[0].index;
              this.setNextPendukungRegion(this.list.activeRegions[i])
              // this.$router.push({
              //   path: '/perolehan-suara-calon/' + this.list.activeRegions[i].id,
              // })
              // chart.data.labels[i] + ': ' + chart.data.datasets[0].data[i]
            }
          }
        },
      });
    },
    initChartVoteCount (chartLabel, chartVoteCountLabels, chartVoteCountDatas, chartVoteCountBackgroundColors, chartVoteCountBorderColors) {
      if (this.chart.suara) {
        this.chart.suara.destroy();
      }
      this.chart.suara = new Chart(document.getElementById('chart-suara-content'), {
        type: 'bar',
        data: {
          labels: chartVoteCountLabels,
          datasets: [{
            axis: 'y',
            label: chartLabel,
            data: chartVoteCountDatas,
            fill: false,
            backgroundColor: chartVoteCountBackgroundColors,
            borderColor: chartVoteCountBorderColors,
            borderWidth: 1
          }]
        },
        options: {
          maintainAspectRatio: window.innerWidth > 768,
          scales: {
            x: {
              grid: {
                display: false
              }
            },
            y: {
              grid: {
                display: true
              }
            }
          },
          indexAxis: 'y',
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              font: {
                size: 13,
                weight: 'bold'
              },
              color: '#fff',
              formatter: (value, context) => {
                return value && value > 0 ? this.$formatPrice(value) : '';
              }
            }
          },
          onClick: (event, elements, chart) => {
            if (elements[0]) {            
              const i = elements[0].index;
              this.$router.push({
                path: '/perolehan-suara-calon/' + this.list.activeRegions[i].id,
              })
            }
            // chart.data.labels[i] + ': ' + chart.data.datasets[0].data[i]
            // const canvasPosition = Chart.helpers.getRelativePosition(e, this.chart.pendukung);
            // // Substitute the appropriate scale IDs
            // const dataX = this.chart.pendukung.scales.x.getValueForPixel(canvasPosition.x);
            // const dataY = this.chart.pendukung.scales.y.getValueForPixel(canvasPosition.y);
            // console.log('dataX', dataX);
            // console.log('dataY', dataY);
          }
        },
      });
    },
    getTotalDPt () {
      this.model.totalDpt = 0
      dptModel.totalDPt({
        'dapil_id': this.gridFilter.city_id,
        // 'parliament_region_id': this.model.parliamentRegion,
      }).then(resp => {
        this.model.totalDpt = resp
      })
    },
    getTotalVote () {
      this.model.totalVoteUse = 0
      this.loading.loadVote = true;
      calegCountSummaryModel.totalVoteByDapilId(this.gridFilter.city_id).then(resp => {
        this.model.totalVoteUse = resp
        this.loading.loadVote = false;
      }).catch(() => {
        this.model.party_vote_counts = []
        this.loading.loadVote = false;
      })
      // let dapilId = this.model.dapil;
      // if (this.model.parliamentRegion !== this.list.parliamentRegions[0].value && this.model.dapil === 'all') {
      //   dapilId = ''
      // }
      // if (this.model.dapil) {
      //   partyModel.totalVote({
      //     'dapil_id': dapilId,
      //     'parliament_region_id': this.model.parliamentRegion,
      //   }).then(resp => {
      //     this.model.totalVoteUse = resp
      //   })
      //   calegCountSummaryModel.totalVoteByParty({
      //     'dapil_id': dapilId,
      //     'parliament_region_id': this.model.parliamentRegion,
      //   }).then(resp => {
      //     this.model.party_vote_counts = []
      //     if (resp.length > 0) {
      //       this.model.party_vote_counts = resp[0]
      //       this.getSingleTotalVotes()
      //     }
      //     this.loading.loadVote = false;
      //   }).catch(() => {
      //     this.model.party_vote_counts = []
      //     this.loading.loadVote = false;
      //   })
      // }
    },
    getSingleTotalVotes () {
      this.list.parties.forEach(party => {
        const filtered = this.model.party_vote_counts.filter(d => {
          return d.id === party.id
        })
        if (filtered.length > 0) {
          party.total_vote_count = filtered[0].total_vote
        }
      })
    }
  }
}
</script>